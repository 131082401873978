import replace from "lodash/replace";
import upperFirst from "lodash/upperFirst";

export default function (toReplace, text) {
  let keyword = null;
  if (typeof window !== "undefined") {
    let params = new URLSearchParams(window.location.search.substring(1));
    keyword = params.get("keyword");
  } else {
    return text;
  }
  if (!keyword) {
    return text;
  }
  keyword = keyword
    .split(" ")
    .map((word) => upperFirst(word))
    .join(" ");
  console.log("KEYWORD", keyword);
  return replace(text, toReplace, keyword);
}
