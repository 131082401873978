import React, { useState } from "react";
import { FaDownload } from "react-icons/fa";
import BrochureDownloadModal from "./BrochureDownloadModal";

function BrochureBottomTag({ service }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className="brochure-cta-bottom"
        onClick={() => {
          setOpen(true);
        }}
      >
        <FaDownload color="white" /> &nbsp; Download Brochure
      </div>
      <BrochureDownloadModal service={service} open={open} setOpen={setOpen} />
    </>
  );
}

export default BrochureBottomTag;
